import ReactGA from 'react-ga4';

export const initGA = () => {
	try {
		ReactGA.initialize('G-R4FJ8WXESH', {
			debug: true,
			gaOptions: {
				debug_mode: true
			}
		});
		console.log('🔍 GA4 initialized successfully');
	} catch (error) {
		console.error('GA4 initialization error:', error);
	}
};

export const trackPageView = (path) => {
	try {
		ReactGA.send({
			hitType: "pageview",
			page: path
		});
		console.log('📊 Page tracked:', path);
	} catch (error) {
		console.error('Tracking error:', error);
	}
};
