import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Products from './pages/Products';
import Booking from './pages/Booking';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import './styles/main.scss';
import SmoothScroll from 'smooth-scroll';
import BackToTop from './components/BackToTop';
import { initGA, trackPageView } from './utils/analytics';

// Analytics tracking component
const Analytics = () => {
	const location = useLocation();

	useEffect(() => {
		// Track page view whenever location changes
		trackPageView(location.pathname + location.search);
	}, [location]);

	return null;
};

function App() {
	useEffect(() => {
		// Initialize GA4
		initGA();

		// Initialize Smooth Scroll
		const scroll = new SmoothScroll('a[data-scroll]', {
			speed: 800,
			offset: 100,
		});

		return () => {
			scroll.destroy();
		};
	}, []);

	return (
		<Router>
			<div className="App">
				<Analytics />
				<Header />
				<main className="main-content">
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/services" element={<Services />} />
						<Route path="/products" element={<Products />} />
						<Route path="/booking" element={<Booking />} />
						<Route path="/gallery" element={<Gallery />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
				</main>
				<Footer />
				<BackToTop />
			</div>
		</Router>
	);
}

export default App;
